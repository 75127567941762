'use strict';

import {debounce, throttle} from 'lodash';
import {clearAllBodyScrollLocks, disableBodyScroll} from 'body-scroll-lock';
import MicroModal from 'micromodal';
import lozad from 'lozad';

/** lozad setup **/
const observer = lozad('.g-lozad', {
    loaded: function (el) {
        let target = el;

        if (!(el instanceof HTMLImageElement)) {
            target = el.querySelector('img');
        }

        target.onload = () => {
            el.classList.add('g-lozad--loaded');
        };
    }
});
observer.observe();

document.addEventListener('DOMContentLoaded', function () {
    let mobile = !window.matchMedia('(min-width: 1245px)').matches;

    handleHeader();
    handleSearchBox();
    handleCheckiOS();
    handleScrollBackTop();
    handleSliders();
    handleBasicSelect();
    handleNavbarSizeOnScroll();
    handleNavbarMenuBackgroundOverlap();
    handleDistributorsPhoneNumber();
    handleReadMore();
    handleInfoBar();
    handleFooterPhoneReveal();
    handleWoocommerceMessagesClose();
    handleMiniCartEvents();
    handlePopups();
    handleFormsSelectAllCheckboxes();
    handleTooltips();
    handleMultipleFormsSending();
    handleCTABlockParallax();
    handleWoocommerceMessagesClose();

    /**
     * Toggles mobile menu on/off
     */
    function handleHeader() {
        document.getElementById('js-header--toggle-nav').addEventListener('click', () => {
            let toggle = document.getElementById('js-header--toggle-nav'),
                nav = document.getElementById('js-header--nav');

            clearAllBodyScrollLocks();

            if (!nav.classList.contains('g-active')) {
                disableBodyScroll(nav);
                toggle.classList.add('g-active');
                nav.classList.add('g-active');

                if (document.querySelector('.g-mini-cart--open')) {
                    document.querySelector('.g-mini-cart--open').classList.remove('g-mini-cart--open');
                }
            } else if (nav.classList.contains('g-submenu-open')) {
                document.querySelectorAll('#js-header--nav .g-megamenu').forEach(item => {
                    item.classList.remove('g-active');
                });

                setTimeout(() => {
                    toggle.classList.remove('g-active');
                    nav.classList.remove('g-active');
                }, 400);
            } else {
                toggle.classList.remove('g-active');
                nav.classList.remove('g-active');
            }
        });

        document.querySelectorAll('.g-menu-header .menu-item-has-children > a').forEach(item => {
            item.addEventListener('click', (e) => {
                if (mobile === true) {
                    e.preventDefault();

                    item.nextElementSibling.classList.add('g-megamenu--overflow', 'g-active');
                    item.closest('.g-header__nav').classList.add('g-submenu-open');

                    if (item.closest('.menu-item').dataset.gDeep === '1') {
                        item.closest('.g-megamenu').classList.remove('g-megamenu--overflow');
                    }
                }
            });
        });
        document.querySelectorAll('.g-megamenu .g-megamenu-back').forEach(item => {
            item.addEventListener('click', (e) => {
                e.preventDefault();

                item.closest('.g-megamenu').classList.remove('g-active');
                item.closest('.g-megamenu').classList.remove('g-megamenu--overflow');

                if (item.closest('.menu-item').dataset.gDeep === '1') {
                    item.closest('.g-megamenu.g-active').classList.add('g-megamenu--overflow');
                } else {
                    item.closest('.g-header__nav').classList.remove('g-submenu-open');
                }
            });
        });

        /** add class to deep 0 ul if li has .current-menu-item class **/
        document.querySelectorAll('li[data-g-deep="0"]').forEach(item => {
            if (item.classList.contains('current-menu-item')) {
                item.parentNode.classList.add('g-has-current-item');
            }
        });

        /** unlock znajdz-instalatora popup for iphone */
        if (document.querySelector('#js-header--nav .g-nav__actions .g-links a[href*="znajdz-instalatora"]')) {
            document.querySelector('#js-header--nav .g-nav__actions .g-links a[href*="znajdz-instalatora"]').addEventListener('click', () => {
                if (mobile) {
                    clearAllBodyScrollLocks();
                }
            });
        }
    }

    /**
     * Header search box open/close
     */
    function handleSearchBox() {
        document.getElementById('js-header--toggle-search').addEventListener('click', (e) => {
            e.preventDefault();
            document.getElementById('js-header--search-form').style.transform = 'translateY(0)';

            /** Focus at end of value */
            document.getElementById('s').focus();
            let val = document.getElementById('s').value;
            document.getElementById('s').value = '';
            document.getElementById('s').value = val;
        });
        document.getElementById('js-header--search-close').addEventListener('click', (e) => {
            e.preventDefault();
            document.getElementById('js-header--search-form').style.transform = 'translateY(-100%)';
        });
        document.getElementById('searchform').addEventListener('submit', (e) => {

            if (!document.getElementById('s').value) {
                e.preventDefault();
                document.getElementById('js-header--search-form').style.transform = 'translateY(-100%)';
            }
        });
    }

    /**
     * Scroll to top functionality
     */
    function handleScrollBackTop() {
        document.getElementById('js-footer--go-top').addEventListener('click', () => {
            window.scrollTo({top: 0, behavior: 'smooth'});
        });
    }

    /**
     * Handles sliders
     */
    function handleSliders() {
        document.querySelectorAll('.g-slider--header').forEach(slider => {
            new Swiper(slider, {
                effect: 'fade',
                speed: 500,
                loop: true,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                pagination: {
                    el: slider.getElementsByClassName('g-slider__pagination')[0],
                    type: 'bullets',
                    clickable: true
                },
                on: {
                    init: function() {
                        controlVideoPlayback(this);
                    },
                    slideChangeTransitionEnd: function() {
                        controlVideoPlayback(this);
                    }
                },
            });
        });

        function controlVideoPlayback(swiper) {
            const activeSlide = swiper.wrapperEl.querySelector('.swiper-slide-active');
        
            swiper.slides.forEach(slide => {
                if (slide !== activeSlide) {
                    const video = slide.querySelector('video');
                    if (video) {
                        video.pause();
                        video.currentTime = 0;
                    }
                }
            });
        
            if (activeSlide) {
                const activeSlideVideo = activeSlide.querySelector('video');
                if (activeSlideVideo) {
                    setTimeout(() => {
                        activeSlideVideo.play().catch(error => {
                            console.error('Video playback failed', error);
                        });
                    }, 50);
                }
            }
        }
        

        function updateAutoplayDelay(swiper) {
            const activeSlide = swiper.wrapperEl.querySelector('.swiper-slide-active');
            const delayAttribute = activeSlide ? activeSlide.getAttribute('data-duration') : null;
            const delay = delayAttribute ? parseInt(delayAttribute, 10) : 5000; // Użyj domyślnego opóźnienia, jeśli nie określono

            swiper.autoplay.stop();
            swiper.params.autoplay.delay = delay;
            swiper.autoplay.start();
        }

        if (document.querySelector('.g-slider--page-header')) {
            let slidesCount = document.querySelectorAll('.g-slider--page-header .g-slide').length;

            const swiper = new Swiper('.g-slider--page-header', {
                effect: 'fade',
                speed: 500,
                loop: slidesCount > 1,
                watchOverflow: true,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                pagination: {
                    el: '#g-page-header-pagination',
                    type: 'bullets',
                    clickable: true
                },
                navigation: {
                    nextEl: '#g-page-header-next',
                    prevEl: '#g-page-header-prev',
                },
                on: {
                    init: function () {
                        observer.observe();
                        updateAutoplayDelay(this);
                        controlVideoPlayback(this);
                    },
                    slideChangeTransitionEnd: function () {
                        updateAutoplayDelay(this);
                        controlVideoPlayback(this);
                    },
                },
            });
        }

    }

    /**
     * Handle navbar size on scroll
     */
    window.addEventListener('scroll', throttle(handleNavbarSizeOnScroll, 100));

    function handleNavbarSizeOnScroll() {
        let navbar = document.getElementById('g-header-container');
        if (document.body.scrollTop >= navbar.clientHeight || document.documentElement.scrollTop >= navbar.clientHeight) {
            navbar.classList.add('g-header-container--scroll');
        } else {
            navbar.classList.remove('g-header-container--scroll');
        }
    }

    /**
     * Site background overlay if navbar menu expended
     */
    function handleNavbarMenuBackgroundOverlap() {
        document.querySelectorAll('.g-menu-header > ul > li').forEach(item => {
            if (item.classList.contains('menu-item-has-children')) {
                item.addEventListener('mouseover', () => {
                    document.getElementsByClassName('g-header-background-overlap')[0].style.display = 'block';
                    if (document.querySelector('.g-mini-cart--open')) {
                        document.querySelector('.g-mini-cart--open').classList.remove('g-mini-cart--open');
                    }
                });
                item.addEventListener('mouseleave', () => {
                    document.getElementsByClassName('g-header-background-overlap')[0].style.display = 'none';
                });
            }
        });

        let btnCall = document.getElementById('js-btn-call');

        if (!btnCall) {
            return;
        }

        btnCall.addEventListener('mouseover', () => {
            document.getElementsByClassName('g-header-background-overlap')[0].style.display = 'block';
        });

        btnCall.addEventListener('mouseleave', () => {
            if (!document.querySelector('.g-mini-cart--open')) {
                document.getElementsByClassName('g-header-background-overlap')[0].style.display = 'none';
            }
        });
    }

    /** show distributor phone number on click **/
    function handleDistributorsPhoneNumber() {
        let btns = document.querySelectorAll('.g-block--distributors .js-phone-number');

        if (!btns) {
            return;
        }

        btns.forEach(item => {
            item.addEventListener('click', (e) => {
                e.preventDefault();

                item.nextElementSibling.style.display = 'block';
                item.style.display = 'none';
            });
        });
    }

    /** Acceptance - read more **/
    function handleReadMore() {
        let readMoreBtns = document.querySelectorAll('form .js-btn-read-more');

        if (!readMoreBtns) {
            return;
        }

        readMoreBtns.forEach(item => {
            item.addEventListener('click', (e) => {
                e.preventDefault();

                if (item.nextElementSibling) {
                    item.style.display = 'none';
                    item.nextElementSibling.style.display = 'inline';
                }
            });
        });
    }

    /**
     * Add margin to main if info bar displayed
     */
    function handleMainWithInfoBar(){
        const infoBar = document.getElementById('js-info-bar'),
            main = document.querySelector('main.g-main');

        if (!infoBar || !main) {
            return;
        }

        main.style.marginTop = `${infoBar.clientHeight}px`;
    }

    /**
     * Info bar display
     */
    function handleInfoBar() {
        const infoBar = document.getElementById('js-info-bar'),
            main = document.querySelector('main.g-main'),
            closeBtn = document.getElementById('js-info-bar-close');

        if (!infoBar || !main) {
            return;
        }

        handleMainWithInfoBar();

        closeBtn.addEventListener('click', () => {
            infoBar.style.display = 'none';
            main.style.marginTop = 0;
        });
    }

    /**
     * Handle Slim Select
     */
    function handleBasicSelect() {
        /**
         * Change all basic selects into SlimSelect
         */
        document.querySelectorAll('.g-select--basic select').forEach(
            selectNode => new SlimSelect({
                select: selectNode,
                showSearch: false,
            })
        );

        /**
         * Redirect to selected URL
         */
        document.querySelectorAll('.g-select--redirect select').forEach(
            element => element.addEventListener('change', () => {
                window.location.assign(element.value);
                return false;
            })
        );
    }

    /**
     * Smooth Scroll to Anchor
     */
    function scrollToElement(el, yOffset = 0) {
        const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
    }

    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
            if (this.getAttribute('href') !== '#' && !this.getAttribute('href').includes(':')) {
                if (document.querySelector(this.getAttribute('href'))) {
                    e.preventDefault();
                    scrollToElement(document.querySelector(this.getAttribute('href')), -80);
                }
            }
        });
    });

    /**
     * Reveal footer phone numbers
     */
    function handleFooterPhoneReveal() {
        document.querySelectorAll('.g-footer__sidebar .g-visible-link').forEach(btn => {
            btn.addEventListener('click', function (e) {
                let selector = '.g-hidden-link';
                let sibling = btn.nextElementSibling;

                if (sibling.matches(selector)) {
                    e.preventDefault();

                    if (sibling.matches('.show')) {
                        sibling.classList.remove('show');
                    } else {
                        sibling.classList.add('show');
                    }
                }
            });
        });
    }

    /**
     * Close woocommerce message box
     */
    function handleWoocommerceMessagesClose() {
        let btns = document.querySelectorAll('.js-woo-message-close');

        if (!btns) {
            return;
        }

        btns.forEach(item => {
            item.addEventListener('click', () => {
                item.parentElement.remove();
            });
        });
    }

    /**
     * Mini Cart | Events
     */
    function handleMiniCartEvents() {
        let cartBtn = document.getElementById('g-mini-cart-js');

        if (!cartBtn) {
            return;
        }

        /**
         * Loading MiniCart contents on page load
         */
        setTimeout(() => handleMiniCartLoading(), 0);

        /**
         * Open/Close MiniCart
         * 0-1244 Click
         * 1245+ Hover
         */
        cartBtn.addEventListener('click', (e) => {
            if (mobile) {
                let cartModal = document.getElementById('js-mini-cart-modal');

                if (!cartModal.contains(e.target)) {
                    if (cartBtn.classList.contains('g-mini-cart--open')) {
                        cartBtn.classList.remove('g-mini-cart--open');
                        document.getElementsByClassName('g-header-background-overlap')[0].style.display = 'none';
                    } else {
                        cartBtn.classList.add('g-mini-cart--open');
                        if (document.querySelector('.g-header__nav.g-active')) {
                            clearAllBodyScrollLocks();
                            document.getElementById('js-header--toggle-nav').classList.remove('g-active');
                            document.querySelector('.g-header__nav.g-active').classList.remove('g-active');
                        } else {
                            document.getElementsByClassName('g-header-background-overlap')[0].style.display = 'block';
                        }
                    }
                }
            }
        });

        cartBtn.addEventListener('mouseover', (e) => {
            if (!mobile) {
                if (!cartBtn.classList.contains('g-mini-cart--open')) {
                    cartBtn.classList.add('g-mini-cart--open');
                    document.getElementsByClassName('g-header-background-overlap')[0].style.display = 'block';
                }
            }
        });

        cartBtn.addEventListener('mouseleave', (e) => {
            if (!mobile) {
                setTimeout(() => {
                    if (!cartBtn.matches(':hover') && !document.querySelector('.g-mini-cart__loading-visible')) {
                        if (cartBtn.classList.contains('g-mini-cart--open')) {
                            cartBtn.classList.remove('g-mini-cart--open');
                            document.getElementsByClassName('g-header-background-overlap')[0].style.display = 'none';
                        }
                    }
                }, 100);
            }
        });

        /**
         * Close MiniCart on overlay
         */
        document.querySelector('.g-header-background-overlap').addEventListener('click', (e) => {
            if (document.querySelector('.g-mini-cart--open')) {
                document.querySelector('.g-mini-cart--open').classList.remove('g-mini-cart--open');
                document.querySelector('.g-header-background-overlap').style.display = 'none';
            }
        });

        /**
         * Update when | added to cart
         * (shop page)
         */
        jQuery(document.body).on('added_to_cart', function (data) {
            handleMiniCartLoading();
        });

        /**
         * Update when | removed from cart
         * (in minicart)
         */
        jQuery(cartBtn).on('click', '.g-mc-item-remove', function (e) {
            if (document.querySelector('.g-mini-cart__inside')) {
                document.querySelector('.g-mini-cart__inside').style.display = 'none';
            }

            if (!document.querySelector('.g-mini-cart__loading-visible')) {
                document.querySelector('.g-mini-cart__loading').classList.add('g-mini-cart__loading-visible');
            }
        });

        jQuery(document.body).on('removed_from_cart', function (data) {
            handleMiniCartLoading();
        });

        /**
         * Update when | all cart page updates
         */
        jQuery(document.body).on('updated_wc_div', function (data) {
            handleMiniCartLoading();
        });
    }

    /**
     * Mini Cart | Loading
     */
    function handleMiniCartLoading() {
        let cartBtn = document.getElementById('g-mini-cart-js');
        let cartModal = document.getElementById('js-mini-cart-modal');
        let loading = document.querySelector('.g-mini-cart__loading');
        let loadingVisible = document.querySelector('.g-mini-cart__loading-visible');

        if (document.querySelector('.g-mini-cart__inside')) {
            document.querySelector('.g-mini-cart__inside').remove();
        }

        if (!loadingVisible) {
            loading.classList.add('g-mini-cart__loading-visible');
        }

        loadMiniCart().then(response => {
            if (response.data) {
                loading.classList.remove('g-mini-cart__loading-visible');

                if (document.querySelector('.g-mini-cart__inside')) {
                    document.querySelector('.g-mini-cart__inside').remove();
                }

                cartModal.innerHTML += response.data;

                if (document.querySelector('.g-mini-cart__products')) {
                    if (document.querySelector('.g-mini-cart--was-full')) {
                        cartBtn.classList.remove('g-mini-cart--was-full');
                    }

                    if (document.querySelector('.g-mini-cart--full')) {
                        cartBtn.classList.remove('g-mini-cart--full');
                    }

                    setTimeout(() => cartBtn.classList.add('g-mini-cart--full'), 100);
                } else {
                    if (document.querySelector('.g-mini-cart--full')) {
                        cartBtn.classList.remove('g-mini-cart--full');
                        cartBtn.classList.add('g-mini-cart--was-full');
                    }
                }
            }
        });
    }

    /**
     * Mini Cart | Fetch
     */
    async function loadMiniCart() {
        let ajaxUrl = _IVN.Ajax.URL;
        const response = await fetch(ajaxUrl, {
            method: 'POST',
            body: new URLSearchParams({
                action: 'load_mini_cart',
                ajaxNonce: _IVN.Ajax.Nonces.LoadMiniCart,
                useNonce: _IVN.Ajax.WooSession
            })
        });

        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            console.log(response);
            return false;
        }
    }

    /**
     * Popups
     */
    function handlePopups() {
        /**
         * Open Popup on URL #gree-popup:name
         */
        document.querySelectorAll('a[href^="#gree-popup:"]').forEach(anchor => {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();
                let popupHref = this.getAttribute('href');
                let popupName = popupHref.split(':')[1];

                if (popupName.length > 0) {
                    if (document.querySelector('#' + popupName)) {
                        MicroModal.show(popupName, {
                            disableScroll: true,
                            awaitCloseAnimation: true
                        });
                    }
                }
            });
        });

        /**
         * Open Popup on URL /znajdz-instalatora
         */
        document.querySelectorAll('a[href*="znajdz-instalatora"]').forEach(anchor => {
            anchor.addEventListener('click', function (e) {
                if (anchor.href.indexOf('#') < 0) {
                    e.preventDefault();

                    if (document.querySelector('#znajdz-instalatora')) {
                        MicroModal.show('znajdz-instalatora', {
                            disableScroll: true,
                            awaitCloseAnimation: true,
                            onClose: (modal) => {
                                if (document.querySelector('#js-header--nav.g-active')) {
                                    disableBodyScroll(document.getElementById('js-header--nav'));
                                }
                            }
                        });
                    }
                }
            });
        });
    }

    /**
     * Forms Select All Checkboxes
     */
    function handleFormsSelectAllCheckboxes() {
        const firstCheckbox = document.querySelector('input[name="g-all-consents[]"]');
        const checkboxes = document.querySelectorAll('div.g-checkboxes input[type="checkbox"]');
        firstCheckbox.addEventListener('change', function () {
            const isChecked = firstCheckbox.checked;
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = isChecked;
            });
        });
    }

    /**
     * Check iOS
     */
    function handleCheckiOS() {
        let iPad = navigator.maxTouchPoints && navigator.userAgent.includes('Safari') && !navigator.userAgent.includes('Chrome');
        let iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

        if (iOS || iPad) {
            document.body.classList.add('ios');
        }
    }

    /**
     * Handle Tooltips
     */
    function handleTooltips() {
        const tooltips = document.querySelectorAll('.g-tooltip');
        if (!tooltips) {
            return;
        }

        tooltips.forEach(tooltip => {
            tooltip.querySelector(':scope > svg').addEventListener('click', e => {
                e.preventDefault();
                if (window.innerWidth < 1280) {
                    tooltip.querySelector('.g-content').classList.add('is-active');
                }
            });

            tooltip.querySelector('.g-content p button').addEventListener('click', e => {
                e.preventDefault();
                tooltip.querySelector('.g-content').classList.remove('is-active');
            });
        });
    }

    /**
     * Resize event
     */
    window.addEventListener('resize', debounce(() => {
        mobile = !window.matchMedia('(min-width: 1245px)').matches;

        if (mobile === false) {
            document.getElementById('js-header--toggle-nav').classList.remove('g-active');
            document.getElementById('js-header--nav').classList.remove('g-active');
        }

        handleMainWithInfoBar();
    }, 100));

    /**
     * Change meta robots
     */
    if (document.querySelector('meta[name="robots"]').content === 'noindex, follow') {
        document.querySelector('meta[name="robots"]').content = 'noindex, nofollow';
    }

    /**
     * Fix for multiple submitting in search all forms
     */
    function handleMultipleFormsSending() {

        const siForm = document.querySelector('form.wpcf7-form');
        if (!siForm) {
            return;
        }

        const siBtn = siForm.querySelector('button[type="submit"]');
        if (!siBtn) {
            return;
        }

        /** disabling on submit */
        siBtn.addEventListener('click', function (event) {
            if (siBtn.disabled === false) {
                siBtn.style.pointerEvents = 'none';
            }
        });

        /** enabling on validation failed */
        document.addEventListener('wpcf7submit', function (event) {
            if (event.detail.status === 'validation_failed' || event.detail.status === 'spam') {
                siBtn.style.pointerEvents = 'all';
            }
        }, false);

    }

    function handleCTABlockParallax() {
        window.addEventListener('scroll', function() {
            var scrollTop = window.scrollY;
            var backgroundElement = document.querySelector('.g-block--cta');
            if (!backgroundElement) {
                return;
            }

            var elementOffsetTop = backgroundElement.offsetTop;
            var windowHeight = window.innerHeight;

            /** We start the parallax effect only when the element is visible on the screen */
            if (scrollTop >= elementOffsetTop - windowHeight) {
                var adjustedScroll = scrollTop - (elementOffsetTop - windowHeight);
                backgroundElement.style.backgroundPosition = 'center ' + (adjustedScroll * -0.3) + 'px';
            } else {
                /** We reset the background position when the element is not visible */
                backgroundElement.style.backgroundPosition = 'center 0px';
            }
        });
    }

});
